import React from 'react';
import { useTranslation } from 'react-i18next';
import photo from '../assets/monica_palma.png';
import '../styles/about.css';

const About: React.FC = () => {

    const { t } = useTranslation();

    return (
        <section id="about">
            <h1>{t('who am i?')}</h1>
            <div className='about-content'>
                <div className='profile_photo'>
                    <img src={photo} alt="Placeholder"/>
                </div>
                <div>
                    <p className="biography">
                        {t('about me text')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;