import React from 'react';
import { useTranslation } from 'react-i18next';
import enFlag from '../assets/english_flag.png';
import ptFlag from '../assets/pt_flag.jpg';
import frFlag from '../assets/fr_flag.png';

import '../styles/header.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <div className='language-switcher'>
      <button className='lang-but' id='en' onClick={() => i18n.changeLanguage('en')}>
        <img src={enFlag} alt="English" className="flag-icon"/>
      </button>
      <button className='lang-but' id='pt' onClick={() => i18n.changeLanguage('pt')}>
        <img src={ptFlag} alt="Portuguese" className="flag-icon"/>
      </button>
      <button className='lang-but' id='fr' onClick={() => i18n.changeLanguage('fr')}>
        <img src={frFlag} alt="French" className="flag-icon"/>
        </button>
    </div>
  );
};

export default LanguageSwitcher;
