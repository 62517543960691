import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import '../styles/contacts-form.css';

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitStatus, setSubmitStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {
      name: '',
      email: '',
      message: '',
    };
    let valid = true;

    emailjs.init(process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY!);

    if (!formData.name) {
      newErrors.name = t('name error');
      valid = false;
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('email error');
      valid = false;
    }
    if (!formData.message) {
      newErrors.message = t('message error');
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      await emailjs.send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID!,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID!,
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
      }).then((result) => {
        setSubmitStatus('success');
        setIsLoading(false);
      }, (error) => {
        console.error('Error submitting form:', error);
        setSubmitStatus('error');
        setIsLoading(false);
      });
    }
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">{t('name')}</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={t('your name')}
            className="form-input"
          />
          {errors.name && <small className="error-text">{errors.name}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder={t('your email')}
            className="form-input"
          />
          {errors.email && <small className="error-text">{errors.email}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="message">{t('message')}</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder={t('your message')}
            className="form-input"
            rows={4}
          />
          {errors.message && <small className="error-text">{errors.message}</small>}
        </div>

        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? t('sending message') : t('send message')}
        </button>

        {submitStatus === 'success' && <p className="success-text">{t('message sent')}</p>}
        {submitStatus === 'error' && <p className="error-text">{t('error sending message')}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
