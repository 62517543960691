import Header from './components/header';
import Image from './components/image';
import Services from './components/services';
import About from './components/about'
import Contacts from './components/contacts';
import './i18n.ts';

function App() {
  return (
    <>
      <Header />
      <Image />
      <Services />
      <About />
      <Contacts />
    </>
  );
}

export default App;
