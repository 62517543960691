import React from 'react';
import { useTranslation } from 'react-i18next';
import med_box from '../assets/med-box.jpg';
import '../styles/image.css';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

const Image = () => {
    const { t } = useTranslation();

    return ( 
        <ParallaxProvider>
            <div className='image'>
                <Parallax translateY={[-40, 40]}>
                    <img src={med_box} alt="Placeholder"/>
                </Parallax>
                <div className="image_text">
                    <h1>{t('hero')}</h1>
                </div>
            </div>
        </ParallaxProvider>
    )
};

export default Image; 
