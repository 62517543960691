import React from "react";
import ContactsForm from "./contacts-form";
import SocialMedia from "./social-media";
import { useTranslation } from 'react-i18next';
import { FaLinkedin, FaFacebook } from 'react-icons/fa';
import { MdLocalPhone } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { IoIosMail } from "react-icons/io";
import '../styles/contacts.css';

const Contacts: React.FC = () => {

    const { t } = useTranslation();

    return (
        <section id="contacts">
            <div className="contacts-content">
                <div className="contacts-header">
                    <h1>{t('contacts')}</h1>
                </div>
                <div className='contacts-body'>
                    <div className="contacts-social">
                        <SocialMedia title="MP Care+ Mónica Palma " link="https://www.facebook.com/mpcaremaismonicapalma" Icon={FaFacebook} />
                        <SocialMedia title="mp_caremais_monicapalma" link="https://www.instagram.com/mp_caremais_monicapalma/" Icon={AiFillInstagram} />
                        <SocialMedia title="monica-palma-6813b7261" link="https://www.linkedin.com/in/monica-palma-6813b7261/" Icon={FaLinkedin} />
                        <SocialMedia title="monicapalma@mpcaremais.com" link={"mailto:monicapalma@mpcaremais.com"} Icon={IoIosMail} />
                        <SocialMedia title="+351 966 791 148" link={undefined} Icon={MdLocalPhone} />
                    </div>
                    <div className="contacts-form">
                        <ContactsForm />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contacts;