import React from "react"
import { IconType } from "react-icons"
import '../styles/social-media.css'

interface SocialMediaProps {
    title: string;
    link: string | undefined;
    Icon: IconType;
}

const SocialMedia: React.FC<SocialMediaProps> = ({title, link, Icon}) => {
    const content = (
        <div className="social-media">
            <Icon className="service-icon" />
            <h3>{title}</h3>
        </div>
    );

    return link ? <a href={link} className="social-media-link">{content}</a> : content;
}

export default SocialMedia;