import React from 'react';
import logo from '../assets/logo.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './language-switcher';
import '../styles/header.css';

const Header: React.FC = () => {
    const { t } = useTranslation();
    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Company Logo"/>
            </div>
            <div className='container'>
                <nav className="nav">
                    <ul>
                        <li><a href="#services">{t('services')}</a></li>
                        <li><a href="#about">{t('about')}</a></li>
                        <li><a href="#contacts">{t('contacts')}</a></li>
                    </ul>
                </nav>
                <LanguageSwitcher />
            </div>
        </header>
    );
};

export default Header;