import React from 'react';
import { useTranslation } from 'react-i18next';
import Service from './service';
import { FaUserMd, FaPills, FaCalendarAlt } from 'react-icons/fa';
import { MdAssignment, MdMonitorHeart, MdHealthAndSafety } from 'react-icons/md';
import { IoPeopleSharp } from "react-icons/io5";

import '../styles/services.css';

const Services: React.FC = () => {

  const { t } = useTranslation();

  const serviceData = [
    {
        title: t('individual care plan'),
        Icon: IoPeopleSharp,
    },
    {
        title: t('review and clarifications on drug therapy'),
        Icon: FaPills,
    },
    {
        title: t('individual preparation of weekly medication'),
        Icon: MdAssignment,
    },
    {
        title: t('assistance in schedualing medical appointments and exams'),
        Icon: FaCalendarAlt,
    },
    {
        title: t('support in medical appointments'),
        Icon: FaUserMd,
    },
    {
        title: t('clarification of access conditions to the portuguese nacional health system-medical assistance'),
        Icon: MdHealthAndSafety,
    },
    {
        title: t('monitoring of some biochemical and physiological paramenters'),
        Icon: MdMonitorHeart,
    },
  ];

  return (
    <section id="services">
      <h1>{t('services')}</h1>
      <div className="service-list">
        {serviceData.map((service, index) => (
          <Service
            key={index}
            title={service.title}
            Icon = {service.Icon}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;