import React from 'react';
import { IconType} from 'react-icons';
import '../styles/service.css';

interface ServiceProps {
  title: string;
  Icon: IconType;
}

const Service: React.FC<ServiceProps> = ({ title, Icon }) => {
  return (
    <div className="service-item">
      <div className="service-icon-container">
        <Icon className="service-icon" />
      </div>
      <h3>{title}</h3>
    </div>
  );
};

export default Service;
